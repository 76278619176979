/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

// regular style toast
@import 'ngx-toastr/toastr';

//ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-single .ng-select-container {
    height: 38px !important;
}

.total-items {
    margin-top: 15px;
}

.button {
    float: right;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    border: solid;
    border-color: #F85F6A;
    border-width: 2px 2px;
    border-radius: 40px;
    margin-left: 10px;
    width: 140px;
    height: 40px;
}

.button-icon {
    float: right;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    border: solid;
    border-color: #F85F6A;
    border-width: 2px 2px;
    border-radius: 40px;
    width: 60px;
    height: 40px;
    margin-left: 10px;
}

.switch {
    padding: 5px;
}

.icon-buttom {
    padding-left: 5px;
    cursor: pointer;
}